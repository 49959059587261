import { lazy, useEffect } from "react"
import * as commonActions from "./redux/commonSlice"
import { useDispatch } from "react-redux"
import { Route, Routes } from "react-router-dom"
import RequireLoggedOut from "./components/generic/RoutesWrappers/RequireLoggedOut"
import RequireLoggedIn from "./components/generic/RoutesWrappers/RequireLoggedIn"
// import TempUserChatPage from "./pages/support/temp";

const LoginPage = lazy(() => import("./pages/login"))
const AccountVerificationPage = lazy(
  () => import("./pages/otp-verification/account-verification")
)
const ForgotPasswordPage = lazy(() => import("./pages/forgot-password"))
const PasswordOtpVerificationPage = lazy(
  () => import("./pages/otp-verification/password-otp-verification")
)
const ResetPasswordPage = lazy(() => import("./pages/reset-password"))

const DashboardPage = lazy(() => import("./pages"))

const PatientListPage = lazy(() => import("./pages/patients"))
const PatientDetailPage = lazy(() => import("./pages/patients/[id]"))
const PatientCasesListPage = lazy(() => import("./pages/patients/[id]/cases"))
const PatientRadiographsPage = lazy(
  () => import("./pages/patients/[id]/radiographs")
)
const PatientEndoscopiesPage = lazy(
  () => import("./pages/patients/[id]/endoscopies")
)
const PatientScheduleListPage = lazy(
  () => import("./pages/patients/[id]/schedules")
)
const PatientChartIdentification = lazy(
  () => import("./pages/patients/[id]/chart/identification")
)
const PatientChartInsurance = lazy(
  () => import("./pages/patients/[id]/chart/insurance")
)
const PatientChartMedicalHistoryPage = lazy(
  () => import("./pages/patients/[id]/chart/medical-surgical")
)
const PatientChartMedicalSurgical = lazy(
  () => import("./pages/patients/[id]/chart/medical-surgical")
)
const PatientChartMedications = lazy(
  () => import("./pages/patients/[id]/chart/medications")
)
const PatientChartAllergiesReactionsPage = lazy(
  () => import("./pages/patients/[id]/chart/allergies-reactions")
)
const PatientChartFamilyHistoryPage = lazy(
  () => import("./pages/patients/[id]/chart/family-histories")
)
const PatientChartSocialReactionsPage = lazy(
  () => import("./pages/patients/[id]/chart/social-recreations")
)
const PatientJournalsPage = lazy(() => import("./pages/patients/[id]/journals"))

const CaseListPage = lazy(() => import("./pages/cases"))
const CaseDetailPage = lazy(() => import("./pages/cases/[id]"))
const ResponsesSummaryPage = lazy(
  () => import("./pages/cases/[id]/responses-summary")
)

const ReferralAuthPage = lazy(() => import("./pages/cases/[id]/referrarl-auth"))
const ReferralAuthCallbackPage = lazy(
  () => import("./pages/google-account-linking/callback")
)

const EducationCenterPage = lazy(() => import("./pages/education-center"))
const CreateEducationMaterialPage = lazy(
  () => import("./pages/education-center/create")
)
const EditEducationMaterialPage = lazy(
  () => import("./pages/education-center/[id]")
)

const TeamMembersListPage = lazy(() => import("./pages/team-members"))
const CreateTeamMemberPage = lazy(() => import("./pages/team-members/create"))
const EditTeamMemberPage = lazy(() => import("./pages/team-members/[id]"))

const ServicesListPage = lazy(() => import("./pages/services"))
const CreateServicePage = lazy(() => import("./pages/services/create"))
const EditServicePage = lazy(() => import("./pages/services/[id]"))

const SpecialistsListPage = lazy(() => import("./pages/specialists"))
const CreateSpecialistPage = lazy(() => import("./pages/specialists/create"))
const EditSpecialistPage = lazy(() => import("./pages/specialists/[id]"))

const MySchedulePage = lazy(() => import("./pages/my-schedule"))

const AdminNotificationPage = lazy(() => import("./pages/notifications"))

const EarningsPage = lazy(() => import("./pages/earnings"))

const SupportPage = lazy(() => import("./pages/support"))

const GoogleLinkingPage = lazy(
  () => import("./pages/google-account-linking/callback")
)

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(commonActions.updateLoginState())
  }, [])

  return (
    <Routes>
      <Route
        path="login"
        element={
          <RequireLoggedOut>
            <LoginPage />
          </RequireLoggedOut>
        }
      />
      <Route
        path="account-verification/:token"
        element={
          <RequireLoggedOut>
            <AccountVerificationPage />
          </RequireLoggedOut>
        }
      />
      <Route
        path="forgot-password"
        element={
          <RequireLoggedOut>
            <ForgotPasswordPage />
          </RequireLoggedOut>
        }
      />
      <Route
        path="password-change-verification/:token"
        element={
          <RequireLoggedOut>
            <PasswordOtpVerificationPage />
          </RequireLoggedOut>
        }
      />
      <Route
        path="reset-password/:token"
        element={
          <RequireLoggedOut>
            <ResetPasswordPage />
          </RequireLoggedOut>
        }
      />

      <Route
        path="/"
        element={
          <RequireLoggedIn>
            <DashboardPage />
          </RequireLoggedIn>
        }
      />

      <Route
        path="/cases"
        element={
          <RequireLoggedIn>
            <CaseListPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/cases/:id"
        element={
          <RequireLoggedIn>
            <CaseDetailPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/cases/:id/responses-summary"
        element={
          <>
            <ResponsesSummaryPage />
          </>
        }
      />
      <Route
        path="/cases/:id/referral-authorization"
        element={
          <>
            <ReferralAuthPage />
          </>
        }
      />

      <Route
        path="/patients"
        element={
          <RequireLoggedIn>
            <PatientListPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/patients/:id"
        element={
          <RequireLoggedIn>
            <PatientDetailPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/patients/:id/cases"
        element={
          <RequireLoggedIn>
            <PatientCasesListPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/patients/:id/radiographs"
        element={
          <RequireLoggedIn>
            <PatientRadiographsPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/patients/:id/endoscopies"
        element={
          <RequireLoggedIn>
            <PatientEndoscopiesPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/patients/:id/schedules"
        element={
          <RequireLoggedIn>
            <PatientScheduleListPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/patients/:id/chart/identification"
        element={
          <RequireLoggedIn>
            <PatientChartIdentification />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/patients/:id/chart/insurance"
        element={
          <RequireLoggedIn>
            <PatientChartInsurance />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/patients/:id/chart/identification"
        element={
          <RequireLoggedIn>
            <PatientChartIdentification />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/patients/:id/chart/medical-surgical"
        element={
          <RequireLoggedIn>
            <PatientChartMedicalSurgical />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/patients/:id/chart/medications"
        element={
          <RequireLoggedIn>
            <PatientChartMedications />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/patients/:id/chart/allergies-reactions"
        element={
          <RequireLoggedIn>
            <PatientChartAllergiesReactionsPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/patients/:id/chart/family-histories"
        element={
          <RequireLoggedIn>
            <PatientChartFamilyHistoryPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/patients/:id/chart/social-recreations"
        element={
          <RequireLoggedIn>
            <PatientChartSocialReactionsPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/patients/:id/journals"
        element={
          <RequireLoggedIn>
            <PatientJournalsPage />
          </RequireLoggedIn>
        }
      />

      <Route
        path="/education-center"
        element={
          <RequireLoggedIn>
            <EducationCenterPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/education-center/create"
        element={
          <RequireLoggedIn>
            <CreateEducationMaterialPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/education-center/:id"
        element={
          <RequireLoggedIn>
            <EditEducationMaterialPage />
          </RequireLoggedIn>
        }
      />

      <Route
        path="/team-members"
        element={
          <RequireLoggedIn>
            <TeamMembersListPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/team-members/create"
        element={
          <RequireLoggedIn>
            <CreateTeamMemberPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/team-members/:id"
        element={
          <RequireLoggedIn>
            <EditTeamMemberPage />
          </RequireLoggedIn>
        }
      />

      <Route
        path="/services"
        element={
          <RequireLoggedIn>
            <ServicesListPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/services/create"
        element={
          <RequireLoggedIn>
            <CreateServicePage />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/services/:id"
        element={
          <RequireLoggedIn>
            <EditServicePage />
          </RequireLoggedIn>
        }
      />

      <Route
        path="/specialists"
        element={
          <RequireLoggedIn>
            <SpecialistsListPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/specialists/create"
        element={
          <RequireLoggedIn>
            <CreateSpecialistPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/specialists/:id"
        element={
          <RequireLoggedIn>
            <EditSpecialistPage />
          </RequireLoggedIn>
        }
      />

      <Route
        path="/my-schedule"
        element={
          <RequireLoggedIn>
            <MySchedulePage />
          </RequireLoggedIn>
        }
      />

      <Route
        path="/notifications"
        element={
          <RequireLoggedIn>
            <AdminNotificationPage />
          </RequireLoggedIn>
        }
      />

      <Route
        path="/earnings"
        element={
          <RequireLoggedIn>
            <EarningsPage />
          </RequireLoggedIn>
        }
      />

      <Route
        path="/support"
        element={
          <RequireLoggedIn>
            <SupportPage />
          </RequireLoggedIn>
        }
      />

      <Route
        path="/google-account-linking/callback"
        element={
          <RequireLoggedIn>
            <GoogleLinkingPage />
          </RequireLoggedIn>
        }
      />
      {/*<Route*/}
      {/*    path="/support/temp"*/}
      {/*    element={<RequireLoggedIn><TempUserChatPage/></RequireLoggedIn>}*/}
      {/*/>*/}

      {/*<Route*/}
      {/*    path=""*/}
      {/*    element={<LandingPage/>}*/}
      {/*/>*/}
    </Routes>
  )
}

export default App
